<template>
  <v-container class="px-6">
    <div class="d-flex">
      <div class="d-flex align-center py-4 pb-6">
        <span class="text-h2 font-weight-bold">SKU绑定</span>
      </div>
    </div>
    <div class="text-h3 font-weight-bold">
      请选择商品以绑定SKU:
    </div>
    <template v-if="isLoading">
      <div
        class="mt-4"
        style="display: grid;grid-template-columns: repeat(6,minmax(0,1fr));grid-gap: 8px;width: 100%"
      >
        <v-card
          v-for="i in dishList"
          :key="i.id"
          class="pa-2"
          elevation="0"
          outlined
          @click="selectDish(i)"
        >
          <v-responsive
            :aspect-ratio="1"
            style="position: relative"
          >
            <div
              class="d-flex align-center justify-center mt-14"
            >
              <div class="text-h5 font-weight-bold">
                {{ i.dishName }}
              </div>
            </div>
          </v-responsive>
        </v-card>
      </div>
    </template>
    <template v-else>
      <div
        class="d-flex align-center justify-center"
        style="height: 400px"
      >
        <v-progress-circular
          indeterminate
          size="64"
        />
      </div>
    </template>
    <v-dialog
      v-model="selectSKUDialog"
      max-width="1200px"
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <div class="text-h3 font-weight-bold">
            {{ selectedDish?.dishName }}:
          </div>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="selectSKUDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-item-group
          v-model="SKUSelect"
          multiple
        >
          <div
            class="mt-4"
            style="display: grid;grid-template-columns: repeat(8,minmax(0,1fr));grid-gap: 8px;width: 100%"
          >
            <v-item
              v-for="i in SKUList"
              :key="i.id"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="d-flex align-center justify-center"
                elevation="0"
                outlined
                @click="toggle()"
              >
                <v-responsive
                  :aspect-ratio="1"
                >
                  <div
                    class="d-flex align-center justify-center flex-column pa-2"
                    style="height: 100%;position: relative"
                  >
                    <v-icon
                      v-if="active"
                      color="primary"
                      size="36"
                      style="position: absolute;bottom: 0px;"
                    >
                      mdi-check-circle
                    </v-icon>
                    <div class="text-center font-weight-bold">
                      {{ i.name }}
                    </div>
                  </div>
                </v-responsive>
              </v-card>
            </v-item>
          </div>
        </v-item-group>
        <div class="d-flex">
          <v-spacer/>
          <v-btn
            class="primary  mt-4 lighten-2"
            elevation="0"
            style="border-radius: 36px"
            @click="SKUSureSelect"
          >
            确定
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Dish from '@/model/dish/Dish'
  import { getDishSKUList, getSKUList, setSKUDish } from '@/common/Utlis/api'
  import { showSuccessMessage } from '@/common/utils'

  export default {
    name: 'ManageSKU',
    data: function () {
      return {
        isLoading: false,
        SKUSelect: [],
        SKUList: null,
        selectSKUDialog: null,
        dishList: null,
        selectedDish: null,
      }
    },
    watch: {
      selectSKUDialog (val) {
        if (!val) {
          this.SKUSelect = []
        }
      },
    },
    mounted () {
      this.reload()
    },
    methods: {
      async SKUSureSelect () {
        await setSKUDish({
          dishId: this.selectedDish.id,
          idSorts: this.SKUSelect.map(it => ({
            id: parseInt(this.SKUList[it].id),
            sort: 1,
          })),
        })
        showSuccessMessage('绑定成功')
        await this.reload()
      },
      async selectDish (item) {
        this.selectSKUDialog = true
        this.selectedDish = item
        const SKUListIndex = []
        const res = await getDishSKUList(item.id)
        for (const item of res) {
          const result = this.SKUList.findIndex(it => it.id === item.id)
          SKUListIndex.push(result)
        }
        this.SKUSelect = SKUListIndex
      },
      async reload () {
        this.isLoading = false
        this.dishList = (await Dish.getList(true)).map(it => {
          it.itemType = 0
          return it
        })
        this.SKUList = await getSKUList()
        this.isLoading = true
      },
    },
  }
</script>

<style scoped>

</style>
